import * as React from 'react';
import * as styles from './terms.module.scss';

import Callout from '../components/v2/callout';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import { Link } from 'gatsby';
import SEO from '../components/seo';
import Section from '../components/section';

const TermsPage = () => {
  return (
    <>
      <SEO
        title="Terms of Service"
        description="Read Interact's Terms of Service to understand your rights and responsibilities when using our quiz creation and marketing services. Learn about user accounts, content policies, and more."
      />
      <Header btnTheme="primary" />
      <main className={styles.terms}>
        <Section theme="white" style={{ marginTop: `4.5rem` }}>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <h1 className="heading-xl color-black">Terms of Service</h1>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ paddingTop: '0' }}>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <p className="text-body color-black">
                <strong>Last Updated: March 24th, 2023</strong>
              </p>
              <p className="text-body color-black">
                These Terms of Service (“Terms”) apply to your access to and use of our website
                located at https://www.tryinteract.com/ (the “Website”), Interact’s online community
                “The Quiz Collective”, and other online products and services (collectively, the
                “Services”) provided by The Quiz Collective, Inc. (d/b/a Interact) (“Interact” or
                “we”).{' '}
                <strong>
                  By clicking to agree to these Terms or by using our Services, you agree to these
                  Terms, including the mandatory arbitration provision and class action waiver in
                  Section 16. If you do not agree to these Terms, do not use our Services.
                </strong>
              </p>
              <p className="text-body color-black">
                If you have any questions about these Terms or our Services, please contact us at
                privacy@tryinteract.com. For information about how we collect, use, share and
                otherwise process information about you, please see our{' '}
                <Link to="/privacy/">Privacy Policy</Link>.
              </p>
              <p className="text-body color-black">
                We may make changes to these Terms from time to time. If we make changes, we will
                post the amended Terms to our Services and update the “Last Updated” date above. We
                may also provide you with additional notice (such as adding a statement to our
                website homepage or sending you a notification). Unless we say otherwise in our
                notice, the amended Terms will be effective immediately and your continued access to
                and use of our Services after we provide notice will confirm your acceptance of the
                changes. If you do not agree to the amended Terms, you must stop accessing and using
                our Services.
              </p>

              <p
                id="overview-of-our-services"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                1. Overview of our Services
              </p>
              <p className="text-body color-black">
                Interact provides an online venue that allows users who comply with Interact’s
                policies to post quizzes, polls, and giveaways as well as find quizzes, polls, and
                giveaways to post through the Services. Interact is not directly involved in the
                transaction between hosts, quiz takers and contestants unless specifically indicated
                that the quiz, poll, or giveaway is hosted by Interact. As a result, Interact has no
                control over the quality, safety, morality or legality of any aspect of the quizzes,
                polls, and giveaways listed, the truth or accuracy of the listings, the ability of
                hosts to provide items or the ability of contestants to qualify for items. You agree
                that Interact Services are a venue and as such that Interact is not responsible or
                liable for any content, for example, data, text, information, usernames, graphics,
                images, photographs, profiles, audio, video, items, and links posted by you, other
                users, or outside parties via the Services. You use the Services at your own risk.
              </p>

              <p
                id="eligibility"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                2. Eligibility
              </p>
              <p className="text-body color-black">
                You must be at least 13 years of age to use our Services. If you are under 18 years
                of age (or the age of legal majority where you live), you may use our Services only
                under the supervision of a parent or legal guardian who agrees to be bound by these
                Terms. If you are a parent or legal guardian of a user under the age of 18 (or the
                age of legal majority), you agree to be fully responsible for the acts or omissions
                of such user in relation to our Services. If you use our Services on behalf of
                another person or entity, (a) all references to “you” throughout these Terms will
                include that person or entity, (b) you represent that you are authorized to accept
                these Terms on that person’s or entity’s behalf, and (c) in the event you or the
                person or entity violates these Terms, the person or entity agrees to be responsible
                to us. Notwithstanding the foregoing, you may not use our Services as an Agency
                unless you have purchased an Agency tier account from us. “Agency” shall mean a
                business or organization providing services on behalf of another business, person,
                group, or entity. Further, if Interact determines, in our sole discretion, that you
                or any such Agency personnel has violated these Terms, your accounts may be
                terminated without prior notification.
              </p>

              <p
                id="user-accounts-and-account-security"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                3. User Accounts and Account Security
              </p>
              <p className="text-body color-black">
                You may need to register for an account to access some or all of our Services. If
                you register for an account, you must provide accurate account information and
                promptly update this information if it changes. You are restricted from sharing your
                account information or password with anyone else and are responsible for keeping
                your account password secure and maintaining the security of your account. You must
                promptly notify us if you discover or suspect that someone has accessed your account
                without your permission.
              </p>

              <p
                id="user-content"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                4. User Content
              </p>
              <ol className="text-body color-black" style={{ listStyleType: `lower-alpha` }}>
                <li>
                  Our Services may allow you and other users to create, post, store and share
                  content, including messages, text, photos, videos, and other materials
                  (collectively, “User Content”).Except for the license you grant below, you retain
                  all rights in and to your User Content, as between you and Interact.
                </li>
                <li>
                  You grant Interact a perpetual, irrevocable, nonexclusive, royalty-free,
                  worldwide, fully paid, and sublicensable license to use, reproduce, modify, adapt,
                  publish, translate, create derivative works from, distribute, publicly perform and
                  display your User Content and any name, username or likeness provided in
                  connection with your User Content in all media formats and channels now known or
                  later developed without compensation to you. Subject to your account settings
                  related to User Content sharing, when you post or otherwise share User Content on
                  or through our Services, you understand that your User Content and any associated
                  information (such as your username or profile photo) may be visible to others.
                </li>
                <li>
                  You may not create, post, store or share any User Content that violates these
                  Terms or for which you do not have all the rights necessary to grant us the
                  license described above. You represent and warrant that your User Content, and our
                  use of such content as permitted by these Terms, will not violate any rights of or
                  cause injury to any person or entity. Although we have no obligation to screen,
                  edit or monitor User Content, we may delete or remove User Content at any time and
                  for any reason with or without notice.
                </li>
              </ol>

              <p
                id="user-content"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                5. Prohibited Conduct and Content
              </p>
              <ol className="text-body color-black" style={{ listStyleType: `lower-alpha` }}>
                <li>
                  You will not violate any applicable law, contract, intellectual property right or
                  other third-party right or commit a tort, and you are solely responsible for your
                  conduct while using our Services. You will not:
                </li>
                <ul className="text-body color-black" style={{ listStyleType: `disc` }}>
                  <li>
                    Engage in any harassing, threatening, intimidating, predatory or stalking
                    conduct;
                  </li>
                  <li>
                    Use or attempt to use another user’s account without authorization from that
                    user and Interact;
                  </li>
                  <li>
                    Impersonate or post on behalf or any person or entity or otherwise misrepresent
                    your affiliation with a person or entity;
                  </li>
                  <li>
                    Sell, resell or commercially use our Services without our prior written consent
                    in each instance. If we consent to such usage, you must visibly credit us as the
                    Source URL in all instances;
                  </li>
                  <li>
                    Copy, reproduce, distribute, publicly perform or publicly display all or
                    portions of our Services, except as expressly permitted by us or our licensors;
                  </li>
                  <li>
                    Modify our Services, remove any proprietary rights notices or markings, or
                    otherwise make any derivative works based upon our Services;
                  </li>
                  <li>
                    Use our Services other than for their intended purpose and in any manner that
                    could interfere with, disrupt, negatively affect or inhibit other users from
                    fully enjoying our Services or that could damage, disable, overburden or impair
                    the functioning of our Services in any manner;
                  </li>
                  <li>
                    Reverse engineer any aspect of our Services or do anything that might discover
                    source code or bypass or circumvent measures employed to prevent or limit access
                    to any part of our Services;
                  </li>
                  <li>
                    Use any data mining, robots or similar data gathering or extraction methods
                    designed to scrape or extract data from our Services;
                  </li>
                  <li>
                    Develop or use any applications that interact with our Services without our
                    prior written consent;
                  </li>
                  <li>
                    Send, distribute or post spam, unsolicited or bulk commercial electronic
                    communications, chain letters, or pyramid schemes;
                  </li>
                  <li>Bypass or ignore instructions contained in our robots.txt file; or</li>
                  <li>
                    Use our Services for any illegal or unauthorized purpose, or engage in,
                    encourage or promote any activity that violates these Terms.
                  </li>
                </ul>
                <li>
                  You may also post or otherwise share only User Content that is nonconfidential and
                  that you have all necessary rights to disclose. You may not create, post, store or
                  share any User Content that:
                </li>
                <ul className="text-body color-black" style={{ listStyleType: `disc` }}>
                  <li>
                    Is unlawful, libelous, defamatory, obscene, pornographic, indecent, lewd,
                    suggestive, harassing, threatening, invasive of privacy or publicity rights,
                    abusive, inflammatory or fraudulent;
                  </li>
                  <li>
                    Would constitute, encourage or provide instructions for a criminal offense,
                    violate the rights of any party or otherwise create liability or violate any
                    local, state, national or international law;
                  </li>
                  <li>
                    May infringe any patent, trademark, trade secret, copyright or other
                    intellectual or proprietary right of any party;
                  </li>
                  <li>
                    Contains or depicts any statements, remarks or claims that do not reflect your
                    honest views and experiences;
                  </li>
                  <li>
                    Impersonates, or misrepresents your affiliation with, any person or entity;
                  </li>
                  <li>
                    Contains any unsolicited promotions, political campaigning, advertising or
                    solicitations;
                  </li>
                  <li>
                    Contains any private or personal information of a third party without such third
                    party’s consent;
                  </li>
                  <li>
                    Contains any viruses, corrupted data or other harmful, disruptive or destructive
                    files or content; or
                  </li>
                  <li>
                    In our sole judgment, is objectionable, restricts or inhibits any other person
                    from using or enjoying our Services, or may expose Interact or others to any
                    harm or liability of any type.
                  </li>
                </ul>
                <li>
                  Enforcement of this Section 5 is solely at Interact’s discretion, and failure to
                  enforce this section in some instances does not constitute a waiver of our right
                  to enforce it in other instances. In addition, this Section 5 does not create any
                  private right of action on the part of any third party or any reasonable
                  expectation that the Services will not contain any content that is prohibited by
                  such rules.
                </li>
              </ol>

              <p
                id="ownership-limited-license"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                6. Ownership; Limited License
              </p>
              <p className="text-body color-black">
                The Services, including the text, graphics, images, photographs, videos,
                illustrations and other content contained therein, are owned by Interact or our
                licensors and are protected under both United States and foreign laws. Except as
                explicitly stated in these Terms, all rights in and to the Services are reserved by
                us or our licensors. Subject to your compliance with these Terms, you are hereby
                granted a limited, nonexclusive, nontransferable, non-sublicensable, revocable
                license to access and use our Services for your own personal use. Any use of the
                Services other than as specifically authorized herein, without our prior written
                permission, is strictly prohibited, will terminate the license granted herein and
                violate our intellectual property rights. You may not access the Services for the
                purpose of creating a product or service competitive with the Interact Services.
              </p>

              <p
                id="trademarks"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                7. Trademarks
              </p>
              <p className="text-body color-black">
                Interact and our logos, our product or service names, our slogans and the look and
                feel of the Services are trademarks of Interact and may not be copied, imitated or
                used, in whole or in part, without our prior written permission. All other
                trademarks, registered trademarks, product names and company names or logos
                mentioned on the Services are the property of their respective owners. Reference to
                any products, services, processes or other information by trade name, trademark,
                manufacturer, supplier or otherwise does not constitute or imply endorsement,
                sponsorship or recommendation by us.
              </p>

              <p
                id="feedback"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                8. Feedback
              </p>
              <p className="text-body color-black">
                You may voluntarily post, submit or otherwise communicate to us any questions,
                comments, suggestions, ideas, original or creative materials or other information
                about Interact or our Services (collectively, “Feedback”). You understand that we
                may use such Feedback for any purpose, commercial or otherwise, without
                acknowledgment or compensation to you, including to develop, copy, publish, or
                improve the Feedback in Interact’s sole discretion. You understand that Interact may
                treat Feedback as nonconfidential.
              </p>

              <p
                id="repeat-infringer-policy"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                9. Repeat Infringer Policy; Copyright Complaints
              </p>
              <p className="text-body color-black">
                In accordance with the Digital Millennium Copyright Act and other applicable law, we
                have adopted a policy of terminating, in appropriate circumstances, the accounts of
                users who repeatedly infringe the intellectual property rights of others. If you
                believe that anything on our Services infringes any copyright that you own or
                control, you may notify Interact at:
              </p>
              <p className="text-body color-black">
                Legal Department
                <br />
                The Quiz Collective, Inc.
                <br />
                2443 Fillmore St #380-14013
                <br />
                San Francisco, CA 94115
                <br />
                privacy@tryinteract.com
              </p>
              <p className="text-body color-black">
                Please see{' '}
                <a
                  href="https://www.gpo.gov/fdsys/pkg/USCODE-2011-title17/pdf/USCODE-2011-title17-chap5-sec512.pdf"
                  target="_blank"
                  rel="noopener noreferrer nofollow">
                  17 U.S.C. § 512(c)(3)
                </a>{' '}
                for the requirements of a proper notification. Also, please note that if you
                knowingly misrepresent that any activity or material on our Services is infringing,
                you may be liable to Interact for certain costs and damages.
              </p>

              <p
                id="third-party-content"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                10. Third-Party Content
              </p>
              <p className="text-body color-black">
                We may provide information about third-party products, services, activities or
                events, or we may allow third parties to make their content and information
                available on or through the Services (collectively, “Third-Party Content”). We
                provide Third-Party Content as a service to those interested in such content. Your
                dealings or correspondence with third parties and your use of or interaction with
                any Third-Party Content are solely between you and the third party. Interact does
                not control or endorse, and makes no representations or warranties regarding, any
                Third-Party Content, and your access to and use of such Third-Party Content is at
                your own risk.
              </p>

              <p
                id="indemnification"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                11. Indemnification
              </p>
              <p className="text-body color-black">
                To the fullest extent permitted by applicable law, you will indemnify, defend and
                hold harmless Interact and our officers, directors, agents, partners and employees
                (individually and collectively, the “Interact Parties”) from and against any losses,
                liabilities, claims, demands, damages, expenses or costs (“Claims”) arising out of
                or related to (a) your access to or use of the Services; (b) your User Content or
                Feedback; (c) your violation of these Terms; (d) your violation, misappropriation or
                infringement of any rights of another (including intellectual property rights or
                privacy rights); or (e) your conduct in connection with the Services. You agree to
                promptly notify Interact Parties of any third-party Claims, cooperate with Interact
                Parties in defending such Claims and pay all fees, costs and expenses associated
                with defending such Claims (including attorneys' fees). You also agree that the
                Interact Parties will have control, at Interact's sole option, of the defense or
                settlement of any third-party Claims. This indemnity is in addition to, and not in
                lieu of, any other indemnities set forth in a written agreement between you and
                Interact or the other Interact Parties.
              </p>

              <p
                id="disclaimers"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                12. Disclaimers
              </p>
              <p className="text-body color-black">
                Your use of our Services is at your sole risk. Except as otherwise provided in a
                writing by us, our Services and any content therein are provided “as is” and “as
                available” without warranties of any kind, either express or implied, including
                implied warranties of merchantability, fitness for a particular purpose, title, and
                non-infringement. In addition, Interact does not represent or warrant that our
                Services are accurate, complete, reliable, current or error-free. While Interact
                attempts to make your use of our Services and any content therein safe, we cannot
                and do not represent or warrant that our Services or servers are free of viruses or
                other harmful components. You assume the entire risk as to the quality and
                performance of the Services.
              </p>

              <p
                id="limitation-of-liability"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                13. Limitation of Liability
              </p>
              <ol className="text-body color-black" style={{ listStyleType: `lower-alpha` }}>
                <li>
                  To the fullest extent permitted by applicable law, Interact and the other Interact
                  Parties will not be liable to you under any theory of liability—whether based in
                  contract, tort, negligence, strict liability, warranty, or otherwise—for any
                  indirect, consequential, exemplary, incidental, punitive or special damages or
                  lost profits, even if Interact or the other Interact Parties have been advised of
                  the possibility of such damages.
                </li>
                <li>
                  The total liability of Interact and the other Interact Parties for any claim
                  arising out of or relating to these Terms or our Services, regardless of the form
                  of the action, is limited to the greater of $100 or the amount paid by you to use
                  our Services.
                </li>
                <li>
                  The limitations set forth in this Section 13 will not limit or exclude liability
                  for the gross negligence, fraud or intentional misconduct of Interact or the other
                  Interact Parties or for any other matters in which liability cannot be excluded or
                  limited under applicable law. Additionally, some jurisdictions do not allow the
                  exclusion or limitation of incidental or consequential damages, so the above
                  limitations or exclusions may not apply to you.
                </li>
              </ol>

              <p id="release" className="heading-md color-black" style={{ marginBottom: `1.5rem` }}>
                14. Release
              </p>
              <p className="text-body color-black" style={{ fontSize: `1rem` }}>
                To the fullest extent permitted by applicable law, you release Interact and the
                other Interact Parties from responsibility, liability, claims, demands and/or
                damages (actual and consequential) of every kind and nature, known and unknown
                (including claims of negligence), arising out of or related to disputes between
                users and the acts or omissions of third parties. If you are a consumer who resides
                in California, you hereby waive your rights under California Civil Code § 1542,
                which provides: “A general release does not extend to claims which the creditor does
                not know or suspect to exist in his or her favor at the time of executing the
                release, which if known by him or her must have materially affected his or her
                settlement with the debtor.”
              </p>

              <p
                id="transfer-and-processing-data"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                15. Transfer and Processing Data
              </p>
              <p className="text-body color-black" style={{ fontSize: `1rem` }}>
                In order for us to provide our Services, you agree that we may process, transfer and
                store information about you in the United States and other countries, where you may
                not have the same rights and protections as you do under local law.
              </p>

              <p
                id="dispute-resolution"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                16. Dispute Resolution; Binding Arbitration
              </p>
              <p className="text-body color-black">
                Please read the following section carefully because it requires you to arbitrate
                certain disputes and claims with Interact and limits the manner in which you can
                seek relief from us, unless you opt out of arbitration by following the instructions
                set forth below. No class or representative actions or arbitrations are allowed
                under this arbitration provision. In addition, arbitration precludes you from suing
                in court or having a jury trial.
              </p>
              <ol className="text-body color-black" style={{ listStyleType: `lower-alpha` }}>
                <li>
                  No Representative Actions. You and Interact agree that any dispute arising out of
                  or related to these Terms or our Services is personal to you and Interact and that
                  any dispute will be resolved solely through individual action, and will not be
                  brought as a class arbitration, class action or any other type of representative
                  proceeding.
                </li>
                <li>
                  Arbitration of Disputes. Except for small claims disputes in which you or Interact
                  seeks to bring an individual action in small claims court located in the county of
                  your billing address or disputes in which you or Interact seeks injunctive or
                  other equitable relief for the alleged infringement or misappropriation of
                  intellectual property, you and Interact waive your rights to a jury trial and to
                  have any other dispute arising out of or related to these Terms or our Services,
                  including claims related to privacy and data security, (collectively, “Disputes”)
                  resolved in court. Instead, for any Dispute that you have against Interact you
                  agree to first contact Interact and attempt to resolve the claim informally by
                  sending a written notice of your claim (“Notice”) to Interact by email at
                  privacy@tryinteract.com or by certified mail addressed to Legal Department, The
                  Quiz Collective, Inc., 2443 Fillmore St #380-14013, San Francisco, CA 94115. The
                  Notice must (a) include your name, residence address, email address, and telephone
                  number; (b) describe the nature and basis of the Dispute; and (c) set forth the
                  specific relief sought. Our notice to you will be similar in form to that
                  described above. If you and Interact cannot reach an agreement to resolve the
                  Dispute within thirty (30) days after such Notice is received, then either party
                  may submit the Dispute to binding arbitration administered by JAMS or, under the
                  limited circumstances set forth above, in court. All Disputes submitted to JAMS
                  will be resolved through confidential, binding arbitration [before one
                  arbitrator]. Arbitration proceedings will be held in San Francisco County,
                  California unless you are a consumer, in which case you may elect to hold the
                  arbitration in your county of residence. For purposes of this Section 16, a
                  “consumer” means a person using the Services for personal, family or household
                  purposes. You and Interact agree that Disputes will be held in accordance with the
                  JAMS Streamlined Arbitration Rules and Procedures (“JAMS Rules”). The most recent
                  version of the JAMS Rules are available on the{' '}
                  <a
                    href="https://www.jamsadr.com/rules-streamlined-arbitration/"
                    target="_blank"
                    rel="noopener noreferrer nofollow">
                    JAMS website
                  </a>{' '}
                  and are hereby incorporated by reference. You either acknowledge and agree that
                  you have read and understand the JAMS Rules or waive your opportunity to read the
                  JAMS Rules and waive any claim that the JAMS Rules are unfair or should not apply
                  for any reason.
                </li>
                <li>
                  You and Interact agree that these Terms affect interstate commerce and that the
                  enforceability of this Section 16 will be substantively and procedurally governed
                  by the Federal Arbitration Act, 9 U.S.C. § 1, et seq. (the “FAA”), to the maximum
                  extent permitted by applicable law. As limited by the FAA, these Terms and the
                  JAMS Rules, the arbitrator will have exclusive authority to make all procedural
                  and substantive decisions regarding any Dispute and to grant any remedy that would
                  otherwise be available in court, including the power to determine the question of
                  arbitrability. The arbitrator may conduct only an individual arbitration and may
                  not consolidate more than one individual’s claims, preside over any type of class
                  or representative proceeding or preside over any proceeding involving more than
                  one individual.
                </li>
                <li>
                  The arbitration will allow for the discovery or exchange of non-privileged
                  information relevant to the Dispute. The arbitrator, Interact, and you will
                  maintain the confidentiality of any arbitration proceedings, judgments and awards,
                  including information gathered, prepared and presented for purposes of the
                  arbitration or related to the Dispute(s) therein. The arbitrator will have the
                  authority to make appropriate rulings to safeguard confidentiality, unless the law
                  provides to the contrary. The duty of confidentiality does not apply to the extent
                  that disclosure is necessary to prepare for or conduct the arbitration hearing on
                  the merits, in connection with a court application for a preliminary remedy or in
                  connection with a judicial challenge to an arbitration award or its enforcement,
                  or to the extent that disclosure is otherwise required by law or judicial
                  decision.
                </li>
                <li>
                  You and Interact agree that for any arbitration you initiate, you will pay the
                  filing fee (up to a maximum of $250 if you are a consumer), and Interact will pay
                  the remaining JAMS fees and costs. For any arbitration initiated by Interact,
                  Interact will pay all JAMS fees and costs. You and Interact agree that the state
                  or federal courts of the State of California and the United States sitting in San
                  Francisco County, California have exclusive jurisdiction over any appeals and the
                  enforcement of an arbitration award.
                </li>
                <li>
                  Any Dispute must be filed within one year after the relevant claim arose;
                  otherwise, the Dispute is permanently barred, which means that you and Interact
                  will not have the right to assert the claim.
                </li>
                <li>
                  You have the right to opt out of binding arbitration within 30 days of the date
                  you first accepted the terms of this Section 16 by mail to Legal Department, The
                  Quiz Collective, Inc., 2443 Fillmore St #380-14013, San Francisco, CA 94115. In
                  order to be effective, the opt-out notice must include your full name and address
                  and clearly indicate your intent to opt out of binding arbitration. By opting out
                  of binding arbitration, you are agreeing to resolve Disputes in accordance with
                  Section 16.
                </li>
                <li>
                  If any portion of this Section 16 is found to be unenforceable or unlawful for any
                  reason, (a) the unenforceable or unlawful provision shall be severed from these
                  Terms; (b) severance of the unenforceable or unlawful provision shall have no
                  impact whatsoever on the remainder of this Section 16 or the parties’ ability to
                  compel arbitration of any remaining claims on an individual basis pursuant to this
                  Section 16; and (c) to the extent that any claims must therefore proceed on a
                  class, collective, consolidated, or representative basis, such claims must be
                  litigated in a civil court of competent jurisdiction and not in arbitration, and
                  the parties agree that litigation of those claims shall be stayed pending the
                  outcome of any individual claims in arbitration. Further, if any part of this
                  Section 16 is found to prohibit an individual claim seeking public injunctive
                  relief, that provision will have no effect to the extent such relief is allowed to
                  be sought out of arbitration, and the remainder of this Section 16 will be
                  enforceable.
                </li>
              </ol>

              <p
                id="governing-law-and-venue"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                17. Governing Law and Venue
              </p>
              <p className="text-body color-black">
                Any dispute arising from these Terms and your use of the Services will be governed
                by and construed and enforced in accordance with the laws of California, except to
                the extent preempted by U.S. federal law, without regard to conflict of law rules or
                principles (whether of California or any other jurisdiction) that would cause the
                application of the laws of any other jurisdiction. Any dispute between the parties
                that is not subject to arbitration or cannot be heard in small claims court will be
                resolved in the state or federal courts of California and the United States,
                respectively, sitting in San Francsico County, California.
              </p>

              <p
                id="modifying-and-terminating-our-services"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                18. Modifying and Terminating our Services
              </p>
              <p className="text-body color-black">
                We reserve the right to modify our Services or to suspend or stop providing all or
                portions of our Services at any time. This includes, but is not limited to, all
                subscription tiers including promotional offers, partner-provided plans, and any
                plans made available through third-party partnerships or resellers. You also have
                the right to stop using our Services at any time. To terminate or cancel your
                subscription, you can do so by logging into your Interact dashboard and doing so
                under your account settings or requesting an agent cancel for you by clicking on
                chat support. You will have access to your Interact account until the end of the
                current active paid term. No refunds will be provided for subscriptions already paid
                for. We are not responsible for any loss or harm related to your inability to access
                or use our Services. If we discontinue providing all or portions of the Services, we
                will, where reasonably possible, give you advance notice and a chance to access and
                move your content and data to another service.
              </p>

              <p
                id="severability"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                19. Severability
              </p>
              <p className="text-body color-black">
                If any provision or part of a provision of these Terms is unlawful, void or
                unenforceable, that provision or part of the provision is deemed severable from
                these Terms and does not affect the validity and enforceability of any remaining
                provisions.
              </p>

              <p
                id="miscellaneous"
                className="heading-md color-black"
                style={{ marginBottom: `1.5rem` }}>
                20. Miscellaneous
              </p>
              <p className="text-body color-black">
                The failure of Interact to exercise or enforce any right or provision of these Terms
                will not operate as a waiver of such right or provision. These Terms reflect the
                entire agreement between the parties relating to the subject matter hereof and
                supersede all prior agreements, representations, statements and understandings of
                the parties. The section titles in these Terms are for convenience only and have no
                legal or contractual effect. Use of the word “including” will be interpreted to mean
                “including without limitation.” Except as otherwise provided herein, these Terms are
                intended solely for the benefit of the parties and are not intended to confer
                third-party beneficiary rights upon any other person or entity. You agree that
                communications and transactions between us may be conducted electronically.
              </p>
            </div>
          </div>
        </Section>
        <Callout />
      </main>
      <Footer />
    </>
  );
};

export default TermsPage;
